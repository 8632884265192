import { InputTypes } from './Input';

export const wrapper = ({ theme, hasError, type }) => ({
    display: 'block',

    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '1rem',
    padding: '0 0.5rem',

    fontSize: '1rem',
    lineHeight: '2rem',

    color: theme.colors.lightBlack,
    backgroundColor: theme.colors.white,

    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',

    borderColor: theme.colors.greyLight,
    ...(hasError && {
        borderColor: theme.colors.red,
        backgroundColor: theme.colors.lightRed,
    }),

    '&:focus': {
        borderColor: '#5D6E85',
    },

    ...(type === InputTypes.TEXTAREA && {
        minHeight: '12rem',
    }),
});

export const checkbox = ({ theme, hasError }) => ({
    position: 'absolute',
    opacity: 0,
    '& + .Label': {
        position: 'relative',
        cursor: 'pointer',
        padding: 0,
        textTransform: 'none',
    },
    '& + .Label:before': {
        content: '" "',
        marginRight: 15,
        display: 'inline-block',
        verticalAlign: 'text-top',
        width: 20,
        height: 20,
        marginTop: -2,
        background: theme.colors.white,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: hasError ? theme.colors.red : theme.colors.greyLight,
        borderRadius: '4px',
    },
    '&:hover + .Label:before': {
        background: theme.colors.white,
    },
    '&:checked + .Label:before': {
        background: theme.colors.white,
    },
    '&:checked + .Label:after': {
        content: '" "',
        position: 'absolute',
        left: 6,
        top: 12,
        background: theme.colors.green,
        width: 2,
        height: 2,
        transform: 'rotate(45deg)',
        boxShadow: `2px 0 0 ${theme.colors.green}, 4px 0 0 ${theme.colors.green}, 4px -2px 0 ${theme.colors.green}, 4px -4px 0 ${theme.colors.green}, 4px -6px 0 ${theme.colors.green}, 4px -8px 0 ${theme.colors.green}`,
    },
});
