import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { combineRules } from 'fela';

import style, { TextSize } from './Text.style';

const Text = ({ size, color, bold, overrideStyles, ...props }) => (
    <FelaComponent size={size} color={color} bold={bold} style={combineRules(style, overrideStyles)}>
        {({ className }) => <p {...props} className={`Text ${props.className} ${className}`} />}
    </FelaComponent>
);

Text.propSizes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(Object.values(TextSize)),
    color: PropTypes.string,
    overrideStyles: PropTypes.func,
    bold: PropTypes.bool.isRequired,
};

Text.defaultProps = {
    className: '',
    size: TextSize.REGULAR,
    color: 'black',
    bold: false,
    overrideStyles: () => {},
};

export default Text;
