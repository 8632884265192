import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import * as componentStyles from './Input.style';

export const InputTypes = {
    INPUT: 'input',
    TEXTAREA: 'textarea',
    CHECKBOX: 'checkbox',
};

const Input = ({ type, hasError, ...props }) => (
    <FelaComponent hasError={hasError} style={componentStyles.wrapper} type={type}>
        {({ className }) => {
            const elementClass = `Input ${type} ${props.className} ${className}`;
            switch (type) {
                case InputTypes.TEXTAREA:
                    return <textarea {...{ ...props, className: elementClass }} />;
                case InputTypes.INPUT:
                    return <input type="text" {...{ ...props, className: elementClass }} />;
                case InputTypes.CHECKBOX: {
                    return (
                        <FelaComponent style={componentStyles.checkbox} hasError={hasError}>
                            {({ className }) => (
                                <input type="checkbox" {...{ ...props, className: `${elementClass} ${className}` }} />
                            )}
                        </FelaComponent>
                    );
                }
                default: {
                    return <></>;
                }
            }
        }}
    </FelaComponent>
);

Input.propTypes = {
    type: PropTypes.oneOf(Object.values(InputTypes)),
    className: PropTypes.string,
    hasError: PropTypes.bool,
};

Input.defaultProps = {
    type: InputTypes.INPUT,
    className: '',
    hasError: false,
};

export default Input;
