import React from 'react';
import { connect as connectFela } from 'react-fela';
import PropTypes from 'prop-types';

import * as componentStyle from './Label.style';

const Label = ({ className, styles, ...props }) => (
    <label {...props} htmlFor={props.htmlFor} className={`Label ${props.htmlFor} ${className} ${styles.label}`} />
);

Label.propTypes = {
    className: PropTypes.string,
};

Label.defaultProps = {
    className: '',
};

export default connectFela(componentStyle)(Label);
