export const TextSize = {
    BIG: 'big',
    REGULAR: 'regular',
    SMALL: 'small',
};

const SIZE_STYLE = {
    [TextSize.BIG]: {
        fontSize: '1.5rem', // 24px
        lineHeight: '2.5rem', // 40px
        fontWeight: '400',
        min768px: {
            fontSize: '1.875rem', // 30px
            lineHeight: '3rem', // 48px
        },
    },
    [TextSize.REGULAR]: {
        fontSize: '1.25rem', // 20px
        lineHeight: '2.125rem', // 34px
        fontWeight: '500',
    },
    [TextSize.SMALL]: {
        fontSize: '1rem', // 16px
        lineHeight: '1.5rem', // 24px
        fontWeight: '500',
    },
};

export default ({ theme, size, color, bold }) => {
    const sizeStyle = SIZE_STYLE[size];
    return {
        ...sizeStyle,
        padding: '0 1rem',
        ...(bold && {
            fontWeight: 'bold',
        }),
        color: theme.colors[color] || color,
    };
};
