import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';

import style from './Button.style';

const Button = props => (
    <FelaComponent style={style}>
        {({ className }) => <button {...props} className={`Button ${props.className} ${className}`} />}
    </FelaComponent>
);

Button.propTypes = {
    className: PropTypes.string,
};

Button.defaultProps = {
    className: '',
};

export default Button;
