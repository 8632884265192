export const label = ({ theme }) => ({
    display: 'block',
    width: '100%',
    color: theme.colors.lightBlack,
    fontFamily: theme.fontFamilyRoboto,
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    fontWeight: 700,
    letterSpacing: '0.87px',
    lineHeight: '2.25rem',
    textAlign: 'left',
    padding: 0,
});
